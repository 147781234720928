<template>
  <b-card bg-variant="light"
          header="Users"
          header-bg-variant="dark"
          header-text-variant="white">

    <b-tabs lazy
            title="Users">
      <users_list :fields="user_fields"></users_list>
    </b-tabs>
  </b-card>
</template>

<script>
  import users_list from '@/components/operational/users/users_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'users_dashboard',
    components: { users_list },

    data() {
      return {
        user_fields: [
          ordinalNumber,
          {
            key: 'first_name',
            label: 'Name',
          },
          {
            key: 'last_name',
            label: 'Surname',
          },
          {
            key: 'email',
            label: 'Email',
          },
          {
            key: 'birth_year',
            label: 'Birth year',
          },
          {
            key: 'affiliations_countries',
            label: 'Affiliation country',
          },
          { key: 'actions' },
        ],
      };
    },
  };
</script>

<style scoped>
</style>
